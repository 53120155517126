// extracted by mini-css-extract-plugin
export const sectionheader = "index-module--sectionheader--DRYu7";
export const user = "index-module--user--HhxCM";
export const question = "index-module--question--2h4os";
export const titleimg = "index-module--titleimg--2lleJ";
export const faqblock = "index-module--faqblock--3loiY";
export const answer = "index-module--answer--3o-SZ";
export const theme = "index-module--theme--3dyFN";
export const faqblockline = "index-module--faqblockline--184xn";
export const faqleft = "index-module--faqleft--296HM";
export const faqright = "index-module--faqright--3MbMn";
export const detailsdiv = "index-module--detailsdiv--2kJlB";